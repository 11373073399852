<template>
    <client-page>
        <sub-visual sh="아카이빙" tabActive="갤러리" text="금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다." image="/images/archiving/visual.jpg" />

        <page-section title="" subtitle="Gallery" class="page-section--first page-section--last">
            <div role="table" class="board-table board-table--view">
                <div role="rowgroup">
                    <div role="row">
                        <div role="columnheader">{{ board?.subject }}</div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <ul class="board-view-data">
                                <li>
                                    작성자 :
                                    {{ board?.writer?.nickname }}
                                </li>
                                <li>
                                    작성일 :
                                    {{ board?.createdAt?.toDate?.() }}
                                </li>
                                <li>
                                    조회수 :
                                    {{ board?.viewCount }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <div class="board-content">
                                <div v-html="board?.content?.replace(/\n/g, '<br>')"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <v-layout justify-end class="mt-24">
                <v-btn outlined color="grey lighten-3" class="h-40px" @click="$router.go(-1)">
                    <span class="grey--text font-size-14">리스트</span>
                </v-btn>
            </v-layout>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { initArchivingBoard } from "@/assets/variables";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data: () => ({
        board: initArchivingBoard(),
    }),
    async mounted() {
        await this.search();
        await this.increaseViewCounts();
    },
    methods: {
        async search() {
            try {
                let { board } = await api.v1.archiving.board.get({ _id: this.$route.params._gallery });
                this.board = board;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async increaseViewCounts() {
            try {
                let increasedCount = this.board.viewCount + 1;
                let { board } = await api.v1.archiving.board.put({ _id: this.$route.params._gallery, viewCount: increasedCount });
                this.board = board;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
}
</style>
