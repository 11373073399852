<template>
    <client-page>
        <sub-visual sh="금산소식" tabActive="공지사항" text="금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다." image="/images/news/visual.jpg" />

        <page-section title="" subtitle="Gallery" class="page-section--first page-section--last">
            <notification-view />
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import NotificationView from '@/components/client/center/notifications/notification-view.vue';

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        NotificationView,
    },
    data: () => ({
    }),
};
</script>

<style lang="scss" scoped>
iframe {
    aspect-ratio: 16/ 9;
}

@media (min-width: 768px) {
}
</style>
