var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('board-list-search', {
    attrs: {
      "code": _vm.BOARD_TYPES.NOTIFICATION.value
    }
  }), _c('table', {
    staticClass: "board-list mt-12 mt-md-20"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.fixed, function (board, index) {
    return _c('tr', {
      key: board._id,
      staticClass: "board-list__info-row"
    }, [_c('td', {
      key: index,
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${board._id}`);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")]), _c('span', {
      staticClass: "ellip"
    }, [_vm._v(_vm._s(board.subject))])], 1), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.createdAt.toDate()) + " ")])]);
  }), _vm._l(_vm.boards, function (board, index) {
    return _c('tr', {
      key: board._id
    }, [_c('td', {
      key: index,
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${board._id}`);
        }
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_vm._v(_vm._s(board.subject))])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.createdAt.toDate()) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "80%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v("작성일")])])]);

}]

export { render, staticRenderFns }