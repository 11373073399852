var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "공간",
      "tabActive": "공간 소개",
      "image": "/images/place/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "대관신청",
      "subtitle": "Reservation",
      "button": "대관신청 안내",
      "buttonLink": "/place/introduction#guide"
    }
  }, [_c('v-row', {
    staticClass: "row--xl place-list"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "swiper-container mb-20"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [[_c('div', {
    staticClass: "swiper-slide"
  }, [_c('v-card', {
    staticClass: "place-list_image rounded-sm",
    attrs: {
      "flat": "",
      "img": "/images/test.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 57 / 38
    }
  })], 1), _c('div', {
    staticClass: "swiper-pagination"
  })], 1)]], 2)]), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-sheet', {
    staticClass: "place-list__title tit tit--sm",
    attrs: {
      "rounded": "pill",
      "color": "grey lighten-5"
    }
  }, [_vm._v("장소명이 들어갑니다.")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-pill",
    attrs: {
      "large": "",
      "width": "120",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "tit tit--xxs font-weight-semibold"
  }, [_vm._v("대관예약")])])], 1)], 1), _c('v-sheet', {
    staticClass: "pt-16 px-16 page-text page-text--lg"
  }, [_c('div', {
    staticClass: "pb-8"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-10",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("위치")]), _c('v-col', {
    staticClass: "grey--text"
  }, [_vm._v("장소 위치가 들어갑니다.")])], 1)], 1), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-10",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("수용인원")]), _c('v-col', {
    staticClass: "grey--text"
  }, [_vm._v("수용인원 수가 들어갑니다.")])], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }