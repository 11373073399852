<template>
    <li>
        <div class="page-text font-weight-medium mb-4">{{ form?.writer?.nickname }}</div>
        <p v-if="!isEdit" class="page-text page-text--sm grey--text mb-8 text-pre-wrap">{{ form?.content }}</p>
        <template v-else>
            <v-divider style="border-color: var(--v-grey-lighten3)" class="mt-8" />
            <v-sheet color="grey lighten-5" class="comment-input pa-12 px-md-20 py-md-16">
                <v-row no-gutters>
                    <v-col>
                        <v-textarea v-model="form.content" no-resize hide-details="auto"/>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="grey darken-4" :disabled="!isWriter" @click="write">
                            <span class="white--text">수정하기</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-divider style="border-color: var(--v-grey-lighten3)" class="mb-8" />
        </template>
        <v-row class="row--xxs page-text page-text--sm grey--text text--lighten-1">
            <v-col cols="auto">
                <div>{{ $dayjs(form?.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
            </v-col>
            <template v-if="isWriter">
                <v-col cols="auto">·</v-col>
                <v-col cols="auto">
                    <div class="cursor-pointer" @click="isEdit = !isEdit">{{ isEdit ? "취소" : "수정" }}</div>
                </v-col>
                <v-col cols="auto">·</v-col>
                <v-col cols="auto">
                    <div class="cursor-pointer" @click="remove">삭제</div>
                </v-col>
            </template>
        </v-row>
    </li>
</template>

<script>
import api from "@/api";
export default {
    props: {
        comment: { type: Object, value: () => ({}) },
    },
    data: () => ({
        isEdit: false,

        form: {},
    }),
    computed: {
        isWriter() {
            return this.comment?.writer?._id == this.$store?.state?.payload?._user;
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = { ...this.comment };
        },
        async write() {
            try {
                await api.v1.boards.comments.put(this.form);

                alert("댓글이 수정되었습니다.");

                this.emit();

                this.isEdit = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async remove() {
            try {
                if (confirm("댓글을 삭제하시겠습니까?")) {
                    await api.v1.boards.comments.delete(this.form);

                    alert("댓글을 삭제하였습니다.");
                    this.emit();
                }
            } catch (error) {
                this.$handleError(error);
            }
        },
        emit() {
            this.$emit("input");
        },
    },
    watch: {
        comment() {
            this.sync();
        },
    },
};
</script>

<style>
</style>