<template>
    <v-row class="row--xxs">
        <v-col cols="12" md="auto">
            <div class="tit line-height-1 font-weight-bold">{{ title }}</div>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="auto">
            <v-select v-model="query['searchKey']" :items="searchKeys" hide-details outlined class="v-input--small w-md-100px" />
        </v-col>
        <v-col md="auto">
            <v-text-field v-model="query['searchValue']" hide-details outlined placeholder="검색어를 입력하세요." class="v-input--small w-md-240px" @keydown.enter="emit" />
        </v-col>
        <v-col cols="auto">
            <v-btn @click="emit" dark color="grey" class="input-btn h-100">검색</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { ARCHIVING_BOARD_TYPES } from "@/assets/variables";
let initQuery = (query = {}) => ({
    ...query,

    searchKey: query?.searchKey || "all",
    searchValue: query?.searchValue || null,
});
export default {
    props: {
        code: { type: String, default: ARCHIVING_BOARD_TYPES.PROJECT.value },
    },
    data: () => ({
        query: initQuery(),
        searchKeys: [
            { text: "전체", value: "all" },
            { text: "제목", value: "subject" },
            { text: "내용", value: "content" },
        ],
    }),
    computed: {
        title() {
            return this.code === ARCHIVING_BOARD_TYPES.PROJECT.value ? "주요사업 내용" : "사진으로 보는 금산 신활력 현장";
        },
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            this.sync();
        },
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            delete query.page;

            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];
            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
};
</script>

<style>
</style>