var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "공간",
      "tabActive": "공간소개",
      "image": "/images/place/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first",
    attrs: {
      "title": "공간소개",
      "subtitle": "Place Introduction"
    }
  }, [_c('div', {
    staticClass: "tit tit--xl font-weight-extrabold text-center mb-18 mb-md-24"
  }, [_vm._v("COMING SOON")]), _c('v-divider', {
    staticClass: "v-divider--dark"
  }), _c('v-sheet', {
    staticClass: "font-size-24 font-size-md-28 py-28 py-md-36 text-center"
  }, [_c('div', [_vm._v(" 금산군 인재들의 창의 활동과 신활력을 불어 넣는 공간, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _c('strong', [_vm._v("우리동네 이음터")]), _vm._v("가 곧 찾아 옵니다. ")])]), _c('v-divider', {
    staticClass: "v-divider--dark"
  })], 1), _c('section', [_c('v-img', {
    attrs: {
      "eager": "",
      "src": "/images/place/place.png"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--last pt-0"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }