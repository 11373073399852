var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "금산소식",
      "tabActive": "자유게시판",
      "text": "금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다.",
      "image": "/images/news/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "board-container"
  }, [_c('board-list-search', {
    attrs: {
      "code": _vm.BOARD_TYPES.COMMUNITY.value
    }
  }), _c('table', {
    staticClass: "board-list mt-12 mt-md-20"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "calc(680 / 1200 * 100%)"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "calc(200 / 1200 * 100%)"
    }
  }, [_vm._v("작성자")]), _c('th', {
    staticStyle: {
      "width": "calc(200 / 1200 * 100%)"
    }
  }, [_vm._v("작성일")]), _c('th', {
    staticStyle: {
      "width": "calc(120 / 1200 * 100%)"
    }
  }, [_vm._v("조회")])])]), _c('tbody', _vm._l(_vm.boards, function (board, index) {
    var _board$category;
    return _c('tr', {
      key: index,
      staticClass: "board-list__info-row"
    }, [_c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${board._id}`);
        }
      }
    }, [_c('span', {
      staticClass: "font-weight-bold primary--text"
    }, [_vm._v("[" + _vm._s(board === null || board === void 0 ? void 0 : (_board$category = board.category) === null || _board$category === void 0 ? void 0 : _board$category.title) + "]")]), _vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.writer.nickname))]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(_vm._s(_vm.$dayjs(board === null || board === void 0 ? void 0 : board.createdAt).format("YYYY-MM-DD")))]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.viewCount))])]);
  }), 0)]), _vm.isLogin ? _c('div', {
    staticClass: "d-flex justify-end mt-18 mt-md-24"
  }, [_c('v-btn', {
    staticClass: "px-18",
    attrs: {
      "outlined": "",
      "height": "40",
      "color": "tertiary"
    },
    on: {
      "click": _vm.write
    }
  }, [_vm._v(" 작성하기 ")])], 1) : _vm._e(), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }