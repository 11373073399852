var render = function render(){
  var _vm$board, _vm$board2, _vm$board3, _vm$board4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" " + _vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.subject) + " ")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v("작성일 : " + _vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.createdAt.toDate()))]), _c('li', [_vm._v("조회수 : " + _vm._s((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.viewCount))])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : _vm$board4.content.replace(/\n/g, '<br>'))
    }
  })])])])])]), _c('v-layout', {
    staticClass: "mt-24",
    attrs: {
      "justify-end": ""
    }
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text font-size-14"
  }, [_vm._v("리스트")])])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }