var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "공간",
      "tabActive": "공간 소개",
      "image": "/images/place/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "대관신청",
      "subtitle": "Reservation"
    }
  }, [_c('v-row', {
    staticClass: "ma-n20"
  }, [_c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [[_c('div', {
    staticClass: "swiper-slide"
  }, [_c('v-card', {
    staticClass: "rounded-sm",
    attrs: {
      "flat": "",
      "img": "/images/test.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 69 / 46
    }
  })], 1), _c('div', {
    staticClass: "swiper-pagination"
  })], 1)]], 2)])]), _c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-sheet', {
    staticClass: "px-16 py-8 tit tit--sm font-weight-bold",
    attrs: {
      "rounded": "pill",
      "color": "grey lighten-5"
    }
  }, [_vm._v("장소명이 들어갑니다.")]), _c('v-sheet', {
    staticClass: "pt-16 px-16 page-text page-text--lg"
  }, [_c('ul', {
    staticClass: "information-list"
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('li', {
      key: index
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "mr-10 font-weight-medium",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-col', {
      staticClass: "grey--text"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1)];
  })], 2)])], 1), _c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm font-weight-bold pb-18 pb-md-24"
  }, [_vm._v("예약일시")]), _c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "rounded": "",
      "outlined": "",
      "elevation": "4"
    }
  }, 'v-card', {
    disabled: _vm.disabled,
    loading: _vm.loading
  }, false), [_c('div', {
    staticClass: "pa-16 pa-md-30 py-xl-80"
  }, [_c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "772"
    }
  }, [_c('rental-form-calendar', _vm._b({
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }, 'rental-form-calendar', {
    subject: _vm.subject
  }, false))], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-sheet', [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg font-weight-bold primary--text"
  }, [_vm._v("이용시간을 선택해주세요")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-end": ""
    }
  }, [_c('v-layout', {
    staticClass: "mr-12 mr-md-20",
    staticStyle: {
      "flex": "unset"
    },
    attrs: {
      "justify-end": "",
      "align-center": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey--text line-height-15"
  }, [_vm._v("예약가능")]), _c('span', {
    staticClass: "state-bar"
  })]), _c('v-layout', {
    staticStyle: {
      "flex": "unset"
    },
    attrs: {
      "justify-end": "",
      "align-center": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey--text line-height-15"
  }, [_vm._v("예약불가")]), _c('span', {
    staticClass: "state-bar grey lighten-4"
  })])], 1)], 1)], 1), _c('v-card', _vm._b({
    staticClass: "mt-16 mt-md-20",
    attrs: {
      "flat": ""
    }
  }, 'v-card', {
    disabled: _vm.disabled,
    loading: _vm.loading
  }, false), [_c('rental-form-schedules', _vm._b({
    model: {
      value: _vm.schedules,
      callback: function ($$v) {
        _vm.schedules = $$v;
      },
      expression: "schedules"
    }
  }, 'rental-form-schedules', {
    date: _vm.date,
    subject: _vm.subject,
    blockages: _vm.blockages,
    schedulesOnCloud: _vm.schedulesOnCloud,
    limitationMet: _vm.limitationMet
  }, false))], 1)], 1), _c('v-sheet', {
    staticClass: "mt-20 mt-md-40"
  }, [_c('p', {
    staticClass: "page-text page-text--lg font-weight-bold primary--text"
  }, [_vm._v("선택내용을 확인해주세요")]), _c('rental-form-preview', _vm._b({
    staticClass: "mt-16 mt-md-20"
  }, 'rental-form-preview', {
    subject: _vm.subject,
    schedules: _vm.schedules
  }, false))], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm font-weight-bold pb-18 pb-md-24"
  }, [_vm._v("사용인원")]), _c('v-textarea', {
    staticClass: "v-textarea--lg rounded",
    attrs: {
      "no-resize": "",
      "outlined": "",
      "hide-details": "",
      "height": "180",
      "placeholder": "사용인원을 입력해주세요"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm font-weight-bold pb-18 pb-md-24"
  }, [_vm._v("대관목적")]), _c('v-textarea', _vm._b({
    staticClass: "v-textarea--lg rounded",
    attrs: {
      "no-resize": "",
      "outlined": "",
      "hide-details": "",
      "height": "180",
      "placeholder": "사용목적을 입력해주세요"
    }
  }, 'v-textarea', {
    disabled: _vm.disabled
  }, false))], 1), _c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "outlined": "",
      "rounded": "",
      "color": "grey lighten-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', _vm._b({
    staticClass: "grey--text"
  }, 'span', {
    loading: _vm.loading
  }, false), [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('dialogue', {
    attrs: {
      "width": "800"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "large": "",
            "rounded": "",
            "color": "primary"
          },
          on: {
            "click": _vm.save
          }
        }, 'v-btn', {
          loading: _vm.loading,
          disabled: _vm.disabled,
          attrs
        }, false), on), [_c('span', [_vm._v("예약하기")])])];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center pb-56",
    attrs: {
      "min-height": "348"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    staticClass: "mb-20 mb-md-40",
    attrs: {
      "size": "64",
      "color": "primary"
    }
  }, [_vm._v("mdi-checkbox-marked-circle-outline")]), _c('div', {
    staticClass: "tit tit--sm font-weight-bold"
  }, [_vm._v("대관예약 신청이 완료되었습니다.")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }