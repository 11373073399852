<template>
    <client-page>
        <sub-visual sh="공간" tabActive="공간 소개" image="/images/place/visual.jpg" />

        <page-section title="대관신청" subtitle="Reservation" class="page-section--first page-section--last">
            <v-row class="ma-n20">
                <v-col cols="12" md="7" class="pa-20">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <template>
                                <div class="swiper-slide">
                                    <v-card flat img="/images/test.jpg" class="rounded-sm">
                                        <v-responsive :aspect-ratio="69 / 46" />
                                    </v-card>

                                    <div class="swiper-pagination" />
                                </div>
                            </template>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="5" class="pa-20">
                    <v-sheet rounded="pill" color="grey lighten-5" class="px-16 py-8 tit tit--sm font-weight-bold">장소명이 들어갑니다.</v-sheet>
                    <v-sheet class="pt-16 px-16 page-text page-text--lg">
                        <ul class="information-list">
                            <template v-for="(item, index) in items">
                                <li :key="index">
                                    <v-row no-gutters>
                                        <v-col cols="auto" class="mr-10 font-weight-medium">
                                            {{ item.title }}
                                        </v-col>
                                        <v-col class="grey--text">
                                            {{ item.text }}
                                        </v-col>
                                    </v-row>
                                </li>
                            </template>
                        </ul>
                    </v-sheet>
                </v-col>
                <v-col cols="12" class="pa-20">
                    <div class="tit tit--sm font-weight-bold pb-18 pb-md-24">예약일시</div>
                    <v-row class="row--xl">
                        <v-col cols="12" md="7">
                            <v-card rounded outlined elevation="4" v-bind="{ disabled, loading }">
                                <div class="pa-16 pa-md-30 py-xl-80">
                                    <v-sheet max-width="772" class="mx-auto">
                                        <rental-form-calendar v-model="date" v-bind="{ subject }" />
                                    </v-sheet>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-sheet>
                                <v-row align="end" class="row--x-small">
                                    <v-col cols="12" md="">
                                        <p class="page-text page-text--lg font-weight-bold primary--text">이용시간을 선택해주세요</p>
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <v-layout justify-end>
                                            <v-layout justify-end align-center class="mr-12 mr-md-20" style="flex: unset">
                                                <p class="page-text grey--text line-height-15">예약가능</p>
                                                <span class="state-bar"></span>
                                            </v-layout>
                                            <v-layout justify-end align-center style="flex: unset">
                                                <p class="page-text grey--text line-height-15">예약불가</p>
                                                <span class="state-bar grey lighten-4"></span>
                                            </v-layout>
                                        </v-layout>
                                    </v-col>
                                </v-row>
                                <v-card flat class="mt-16 mt-md-20" v-bind="{ disabled, loading }">
                                    <rental-form-schedules v-model="schedules" v-bind="{ date, subject, blockages, schedulesOnCloud, limitationMet }" />
                                </v-card>
                            </v-sheet>
                            <v-sheet class="mt-20 mt-md-40">
                                <p class="page-text page-text--lg font-weight-bold primary--text">선택내용을 확인해주세요</p>
                                <rental-form-preview v-bind="{ subject, schedules }" class="mt-16 mt-md-20" />
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pa-20">
                    <div class="tit tit--sm font-weight-bold pb-18 pb-md-24">사용인원</div>
                    <v-textarea no-resize outlined hide-details height="180" placeholder="사용인원을 입력해주세요" class="v-textarea--lg rounded" />
                </v-col>
                <v-col cols="12" class="pa-20">
                    <div class="tit tit--sm font-weight-bold pb-18 pb-md-24">대관목적</div>
                    <v-textarea no-resize outlined hide-details height="180" placeholder="사용목적을 입력해주세요" class="v-textarea--lg rounded" v-bind="{ disabled }" />
                </v-col>
                <v-col cols="12" class="pa-20">
                    <v-row justify="center" class="row--xxs">
                        <v-col cols="auto">
                            <v-btn @click="$router.go(-1)" large outlined rounded color="grey lighten-4">
                                <span class="grey--text" v-bind="{ loading }">취소</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <dialogue width="800" v-model="shows">
                                <template #activator="{ on, attrs }">
                                    <v-btn large rounded color="primary" v-bind="{ loading, disabled, attrs }" v-on="on" @click="save">
                                        <span>예약하기</span>
                                    </v-btn>
                                </template>

                                <v-sheet min-height="348" class="d-flex align-center pb-56">
                                    <v-container class="text-center">
                                        <v-icon size="64" color="primary" class="mb-20 mb-md-40">mdi-checkbox-marked-circle-outline</v-icon>
                                        <div class="tit tit--sm font-weight-bold">대관예약 신청이 완료되었습니다.</div>
                                    </v-container>
                                </v-sheet>
                            </dialogue>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import RentalFormCalendar from "@/components/client/rental/form/rental-form-calendar.vue";
import Dialogue from "@/components/client/dumb/dialogue.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        RentalFormCalendar,
        Dialogue,
    },
    data: () => ({
        shows: false,
        items: [
            {
                title: "위치",
                text: "장소 위치가 들어갑니다.",
            },
            {
                title: "면적",
                text: "장소 면적이 들어갑니다.",
            },
            {
                title: "수용인원",
                text: "장소의 수용인원이 들어갑니다.",
            },
            {
                title: "부대시설",
                text: "장소의 부대시설이 들어갑니다.",
            },
            {
                title: "유의사항",
                text: "장소의 유의사항이 들어갑니다.",
            },
        ],
    }),
    mounted() {
        this.$nextTick(() => {
            var placeSlide = new Swiper(".swiper-container", {
                effect: "fade",
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                speed: 500,
                loop: true,
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
    position: relative;
}
.swiper-pagination {
    left: 24px;
    bottom: 24px;
    top: initial;
}
.swiper-pagination-bullets {
    margin: -6px;
    ::v-deep {
        .swiper-pagination-bullet {
            margin: 6px;
            background-color: #fff;
            width: 8px;
            height: 8px;
            opacity: 0.4;
            &-active {
                width: 20px;
                height: 20px;
                background-color: transparent;
                border: 1px solid #fff;
                position: relative;
                opacity: 1;
                &::after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #fff;
                    border-radius: 50%;
                }
            }
        }
    }
}
.information-list {
    > li {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.state-bar {
    display: block;
    width: 30px;
    height: 8px;
    border-radius: 999px;
    border: 1px solid var(--v-grey-lighten4);
    margin-left: 8px;
}
@media (min-width: 768px) {
}
</style>
