<template>
    <client-page>
        <sub-visual sh="금산소식" tabActive="자유게시판" text="금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다." image="/images/news/visual.jpg" />

        <page-section class="page-section--first page-section--last">
            <div role="table" class="board-table board-table--view">
                <div role="rowgroup">
                    <div role="row">
                        <div role="columnheader">{{ board?.subject }}</div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <ul class="board-view-data">
                                <li>
                                    작성일 :
                                    {{ board?.createdAt?.toDate?.() }}
                                </li>
                                <li>
                                    조회수 :
                                    {{ board?.viewCount }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <div class="board-content">
                                <div v-html="board?.content?.replace(/\n/g, '<br>')"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-24">
                <v-row class="row--xxs">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="h-40px" @click="$router.go(-1)">
                            <span class="grey--text font-size-14">리스트</span>
                        </v-btn>
                    </v-col>
                    <v-col v-if="isWriter" cols="auto">
                        <v-btn outlined color="secondary" class="h-40px" @click="remove">
                            <span class="font-size-14">삭제하기</span>
                        </v-btn>
                    </v-col>
                    <v-col v-if="isWriter" cols="auto">
                        <v-btn outlined color="tertiary" class="h-40px" @click="edit">
                            <span class="font-size-14">수정하기</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <board-comments v-bind="{ comments: board?.comments }" class="mt-30 mt-md-40" @input="init"/>
        </page-section>
    </client-page>
</template>

<script>
import api from '@/api';
import { initBoard } from '@/assets/variables';
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

import BoardComments from "@/components/client/board/board-comments.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,

        BoardComments,
    },
    data() {
        return {
            board: initBoard(),
        };
    },
    computed: {
        isWriter() {
            return this.board?.writer?._id === this.$store.state.payload._user;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { board } = await api.v1.boards.get({ _id: this.$route.params._board });
                this.board = initBoard(board);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async remove() {
            try {
                if(confirm("해당 게시글을 삭제하겠습니까?")){
                    await api.v1.boards.delete({ _id: this.$route.params._board });

                    alert("삭제되었습니다.");
                    this.$router.go(-1);
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        edit(){
            this.$router.push(`./${this.board._id}/modify`);
        }
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
}
</style>
