<template>
    <div class="board-container">
        <board-list-search :code="BOARD_TYPES.NOTIFICATION.value" />
        <table class="board-list mt-12 mt-md-20">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <!-- <th style="width: %">번호</th> -->
                    <th style="width: 80%">제목</th>
                    <!-- <th style="width: %">작성자</th> -->
                    <th style="width: 20%">작성일</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(board, index) in fixed" :key="board._id" class="board-list__info-row">
                    <td align="center" class="board-list__tit" :key="index" @click="$router.push(`${$route.path}/${board._id}`)">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                        <span class="ellip">{{ board.subject }}</span>
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.createdAt.toDate() }}
                    </td>
                </tr>
                <tr v-for="(board, index) in boards" :key="board._id">
                    <td align="center" class="board-list__tit" :key="index" @click="$router.push(`${$route.path}/${board._id}`)">
                        <div class="ellip">{{ board.subject }}</div>
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.createdAt.toDate() }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="v-pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { BOARD_TYPES } from "@/assets/variables";
import BoardListSearch from "@/components/client/board/board-list-search.vue"

export default {
    components: {
        BoardListSearch,
    },
    data: () => ({
        BOARD_TYPES,
        fixed: [],
        boards: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        params() {
            let { ...query } = this.$route.query;
            query.code = BOARD_TYPES.NOTIFICATION.value;
            query.isNotice = false;

            return query;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { boards: fixed } = await api.v1.boards.gets({
                    headers: { limit: 0 },
                    params: { isNotice: true, code: BOARD_TYPES.NOTIFICATION.value },
                });

                this.fixed = fixed;

                const { skip, limit, params } = this;
                let { summary, boards } = await api.v1.boards.gets({
                    headers: { skip, limit },
                    params,
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
