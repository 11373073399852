<template>
    <client-page>
        <sub-visual sh="아카이빙" tabActive="갤러리" text="금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다." image="/images/archiving/visual.jpg" />

        <page-section title="" subtitle="Gallery" class="page-section--first page-section--last">
            <div class="tit line-height-1 font-weight-bold mb-22 mb-md-28">금산군 신활력플러스사업 소개 영상</div>
            <iframe width="100%" :src="`https://www.youtube.com/embed/${youtubeCode}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="rounded-sm mb-60 mb-md-80" />

            <div class="board-container">
                <board-list-search :code="ARCHIVING_BOARD_TYPES.GALLERY.value"/>
                <v-divider class="v-divider--dark my-18 my-md-24" />
                <v-row>
                    <v-col v-for="(board, index) in boards" :key="index" cols="6" md="3">
                        <v-card tile flat :to="`./gallery/${board?._id}`">
                            <v-card :img="board?.thumb?.src" flat class="rounded-sm">
                                <v-responsive :aspect-ratio="1 / 1" />
                            </v-card>

                            <v-card-title class="pt-24 pb-0 px-0 font-weight-medium grey--text text--darken-4">
                                <div class="text-truncate-2">{{ board?.subject }}</div>
                            </v-card-title>
                            <v-card-text class="px-0 py-16 grey--text">{{ $dayjs(board?.createdAt).format("YYYY-MM-DD") }} </v-card-text>
                            <v-divider />
                        </v-card>
                    </v-col>
                </v-row>
                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" :total-visible="20" @input="(page) => $router.push({ query: { page } })"/>
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { ARCHIVING_BOARD_TYPES } from "@/assets/variables";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import BoardListSearch from "@/components/client/archiving/board-list-search.vue"

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        BoardListSearch
    },
    data: () => ({
        ARCHIVING_BOARD_TYPES,
        boards: [],
        summary: { totalCount: 0 },

        page: 1,
        limit: 8,
    }),
    computed: {
        youtubeCode() {
            return this.$store.state.setting?.archiving?.youtubeCode;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        query() {
            let { ...query } = this.$route.query;

            query.code = ARCHIVING_BOARD_TYPES.GALLERY.value;
            return query;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search();
        },
        async search() {
            try {
                let { summary, boards } = await api.v1.archiving.board.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.query,
                });

                this.boards = boards;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        query() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
iframe {
    aspect-ratio: 16/ 9;
}

@media (min-width: 768px) {
}
</style>
