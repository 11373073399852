var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "아카이빙",
      "tabActive": "프로젝트",
      "text": "금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다.",
      "image": "/images/archiving/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first",
    attrs: {
      "title": "주요사업",
      "subtitle": "Project"
    }
  }, [_c('div', {
    staticClass: "tit text-center font-weight-bold pb-18 pb-md-24"
  }, [_vm._v("추진성과")]), _c('div', {
    staticClass: "px-xl-124"
  }, [_c('v-row', [_vm._l(_vm.result, function (item, index) {
    return [_c('v-col', {
      key: index,
      staticClass: "result-list-item",
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": "",
        "outlined": "",
        "rounded": "circle"
      }
    }, [_c('v-responsive', {
      staticClass: "d-flex text-center align-center",
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('div', {
      staticClass: "tit tit--xxs line-height-1 grey--text text--darken-4 mb-6 mb-md-8"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "tit tit--lg font-weight-bold line-height-1"
    }, [_c('span', {
      staticClass: "font-weight-regular"
    }, [_vm._v("+")]), _vm._v(_vm._s(item.number))])])], 1)], 1)];
  })], 2)], 1)]), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('div', {
    staticClass: "board-container"
  }, [_c('board-list-search', {
    attrs: {
      "code": _vm.ARCHIVING_BOARD_TYPES.PROJECT.value
    }
  }), _c('v-divider', {
    staticClass: "v-divider--dark my-18 my-md-24"
  }), _c('v-row', _vm._l(_vm.boards, function (board, index) {
    var _board$thumb;
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": `./project/${board === null || board === void 0 ? void 0 : board._id}`
      }
    }, [_c('v-card', {
      staticClass: "rounded-sm",
      attrs: {
        "img": board === null || board === void 0 ? void 0 : (_board$thumb = board.thumb) === null || _board$thumb === void 0 ? void 0 : _board$thumb.src,
        "flat": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 385 / 376
      }
    })], 1), _c('v-card-title', {
      staticClass: "pt-24 px-0"
    }, [_c('div', {
      staticClass: "text-truncate-2 font-weight-medium grey--text text--darken-4"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.subject))])]), _c('v-sheet', {
      staticClass: "responsive-radius px-12 py-8",
      attrs: {
        "color": "grey lighten-5"
      }
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "tertiary--text font-weight-bold"
    }, [_vm._v("사업기간")])]), _c('v-col', [_c('div', {
      staticClass: "grey--text"
    }, [_vm._v(_vm._s(_vm.$dayjs(board === null || board === void 0 ? void 0 : board.businessStartsAt).format("YYYY.MM.DD")) + " ~ " + _vm._s(_vm.$dayjs(board === null || board === void 0 ? void 0 : board.businessEndsAt).format("YYYY.MM.DD")))])])], 1)], 1), _c('v-card-text', {
      staticClass: "px-12 pt-8 pb-16"
    }, [_c('div', {
      staticClass: "text-truncate-2"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.summary))])]), _c('v-divider')], 1)], 1);
  }), 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: {
            page
          }
        });
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }