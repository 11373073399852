var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "아카이빙",
      "tabActive": "갤러리",
      "text": "금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다.",
      "image": "/images/archiving/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "",
      "subtitle": "Gallery"
    }
  }, [_c('div', {
    staticClass: "tit line-height-1 font-weight-bold mb-22 mb-md-28"
  }, [_vm._v("금산군 신활력플러스사업 소개 영상")]), _c('iframe', {
    staticClass: "rounded-sm mb-60 mb-md-80",
    attrs: {
      "width": "100%",
      "src": `https://www.youtube.com/embed/${_vm.youtubeCode}`,
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  }), _c('div', {
    staticClass: "board-container"
  }, [_c('board-list-search', {
    attrs: {
      "code": _vm.ARCHIVING_BOARD_TYPES.GALLERY.value
    }
  }), _c('v-divider', {
    staticClass: "v-divider--dark my-18 my-md-24"
  }), _c('v-row', _vm._l(_vm.boards, function (board, index) {
    var _board$thumb;
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": `./gallery/${board === null || board === void 0 ? void 0 : board._id}`
      }
    }, [_c('v-card', {
      staticClass: "rounded-sm",
      attrs: {
        "img": board === null || board === void 0 ? void 0 : (_board$thumb = board.thumb) === null || _board$thumb === void 0 ? void 0 : _board$thumb.src,
        "flat": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('v-card-title', {
      staticClass: "pt-24 pb-0 px-0 font-weight-medium grey--text text--darken-4"
    }, [_c('div', {
      staticClass: "text-truncate-2"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.subject))])]), _c('v-card-text', {
      staticClass: "px-0 py-16 grey--text"
    }, [_vm._v(_vm._s(_vm.$dayjs(board === null || board === void 0 ? void 0 : board.createdAt).format("YYYY-MM-DD")) + " ")]), _c('v-divider')], 1)], 1);
  }), 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: {
            page
          }
        });
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }