<template>
    <div>
        <div class="tit tit--xs font-weight-bold mb-16">
            댓글
            <span class="tertiary--text">{{ comments.length }}</span>
        </div>
        <ul class="board-comments__list">
            <template v-for="(comment, index) in comments">
                <board-comment v-bind="{ comment }" :key="index" @input="emit" />
            </template>
        </ul>
        <v-sheet color="grey lighten-5" class="comment-input pa-12 px-md-20 py-md-16">
            <v-row no-gutters>
                <v-col>
                    <v-textarea placeholder="댓글을 입력하세요." height="80" v-model="content" no-resize hide-details="auto" />
                </v-col>
                <v-col cols="auto">
                    <v-btn color="grey darken-4" :disabled="!isLogin" @click="write">
                        <span class="white--text">등록하기</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-divider style="border-color: var(--v-grey-lighten3)" />
    </div>
</template>

<script>
import api from "@/api";
import BoardComment from "./board-comment.vue";
export default {
    components: {
        BoardComment,
    },
    props: {
        comments: { type: Array, default: () => [] },
    },
    data: () => ({
        content: "",
    }),
    computed: {
        isLogin() {
            return this.$store.state.payload?._user;
        },
    },
    methods: {
        async write() {
            try {
                let _board = this.$route.params._board;
                if (!_board) throw new Error("게시글이 존재하지 않습니다.");

                const form = {
                    _board,
                    content: this.content || null,
                };

                await api.v1.boards.comments.post(form);

                this.content = "";

                alert("댓글을 작성하였습니다.");

                this.emit();
            } catch (error) {
                this.$handleError(error);
            }
        },
        emit() {
            this.$emit("input");
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .board-comments__list {
        border-top: 2px solid var(--v-grey-darken4);
        > li {
            border-bottom: 1px solid var(--v-grey-lighten3);
            padding: 16px 0;
        }
    }
    .comment-input {
        .v-textarea {
            height: 80px;
            border-radius: 8px 0 0 8px !important;
            ::v-deep {
                .v-input__slot {
                    padding: 12px;
                }
                textarea {
                    padding-bottom: 0;
                }
            }
        }
        .v-btn {
            height: 100%;
            border-radius: 0 8px 8px 0;
        }
    }

    @media (min-width: 768px) {
        .comment-input {
            .v-textarea {
                .v-input__slot {
                    padding: 16px;
                }
            }
        }
    }
}
</style>