var render = function render(){
  var _vm$board, _vm$board2, _vm$board2$writer, _vm$board3, _vm$board3$createdAt, _vm$board3$createdAt$, _vm$board4, _vm$board5, _vm$board5$content;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "아카이빙",
      "tabActive": "프로젝트",
      "text": "금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다.",
      "image": "/images/archiving/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.subject))])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v(" 작성자 : " + _vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : (_vm$board2$writer = _vm$board2.writer) === null || _vm$board2$writer === void 0 ? void 0 : _vm$board2$writer.nickname) + " ")]), _c('li', [_vm._v(" 작성일 : " + _vm._s((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : (_vm$board3$createdAt = _vm$board3.createdAt) === null || _vm$board3$createdAt === void 0 ? void 0 : (_vm$board3$createdAt$ = _vm$board3$createdAt.toDate) === null || _vm$board3$createdAt$ === void 0 ? void 0 : _vm$board3$createdAt$.call(_vm$board3$createdAt)) + " ")]), _c('li', [_vm._v(" 조회수 : " + _vm._s((_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : _vm$board4.viewCount) + " ")])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : (_vm$board5$content = _vm$board5.content) === null || _vm$board5$content === void 0 ? void 0 : _vm$board5$content.replace(/\n/g, '<br>'))
    }
  })])])])])]), _c('v-layout', {
    staticClass: "mt-24",
    attrs: {
      "justify-end": ""
    }
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text font-size-14"
  }, [_vm._v("리스트")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }