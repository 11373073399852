var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "tit tit--xs font-weight-bold mb-16"
  }, [_vm._v(" 댓글 "), _c('span', {
    staticClass: "tertiary--text"
  }, [_vm._v(_vm._s(_vm.comments.length))])]), _c('ul', {
    staticClass: "board-comments__list"
  }, [_vm._l(_vm.comments, function (comment, index) {
    return [_c('board-comment', _vm._b({
      key: index,
      on: {
        "input": _vm.emit
      }
    }, 'board-comment', {
      comment
    }, false))];
  })], 2), _c('v-sheet', {
    staticClass: "comment-input pa-12 px-md-20 py-md-16",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-textarea', {
    attrs: {
      "placeholder": "댓글을 입력하세요.",
      "height": "80",
      "no-resize": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "grey darken-4",
      "disabled": !_vm.isLogin
    },
    on: {
      "click": _vm.write
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("등록하기")])])], 1)], 1)], 1), _c('v-divider', {
    staticStyle: {
      "border-color": "var(--v-grey-lighten3)"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }