<template>
    <client-page>
        <sub-visual sh="공간" tabActive="공간 소개" image="/images/place/visual.jpg" />

        <page-section title="대관신청" subtitle="Reservation" button="대관신청 안내" buttonLink="/place/introduction#guide" class="page-section--first page-section--last">
            <v-row class="row--xl place-list">
                <v-col cols="12" md="6">
                    <div class="swiper-container mb-20">
                        <div class="swiper-wrapper">
                            <template>
                                <div class="swiper-slide">
                                    <v-card flat img="/images/test.jpg" class="place-list_image rounded-sm">
                                        <v-responsive :aspect-ratio="57 / 38" />
                                    </v-card>

                                    <div class="swiper-pagination" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <v-row align="center" class="row--xxs">
                        <v-col>
                            <v-sheet rounded="pill" color="grey lighten-5" class="place-list__title tit tit--sm">장소명이 들어갑니다.</v-sheet>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn large width="120" color="primary" class="rounded-pill">
                                <span class="tit tit--xxs font-weight-semibold">대관예약</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-sheet class="pt-16 px-16 page-text page-text--lg">
                        <div class="pb-8">
                            <v-row no-gutters>
                                <v-col cols="auto" class="mr-10">위치</v-col>
                                <v-col class="grey--text">장소 위치가 들어갑니다.</v-col>
                            </v-row>
                        </div>
                        <div>
                            <v-row no-gutters>
                                <v-col cols="auto" class="mr-10">수용인원</v-col>
                                <v-col class="grey--text">수용인원 수가 들어갑니다.</v-col>
                            </v-row>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>
            <div class="v-pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="20" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data: () => ({}),
    mounted() {
        this.$nextTick(() => {
            var placeSlide = new Swiper(".swiper-container", {
                effect: "fade",
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                speed: 500,
                loop: true,
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.place-list {
    &__title {
        height: 44px;
        font-weight: 700;
        padding: 8px 16px;
        display: flex;
        align-items: center;
    }

    .swiper-slide {
        position: relative;
    }
    .swiper-pagination {
        left: 24px;
        bottom: 24px;
        top: initial;
    }
    .swiper-pagination-bullets {
        margin: -6px;
        ::v-deep {
            .swiper-pagination-bullet {
                margin: 6px;
                background-color: #fff;
                width: 8px;
                height: 8px;
                opacity: 0.4;
                &-active {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    border: 1px solid #fff;
                    position: relative;
                    opacity: 1;
                    &::after {
                        content: "";
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .place-list {
        &__title {
            height: 48px;
        }
    }
}
</style>
