var render = function render(){
  var _vm$form, _vm$form$writer, _vm$form2, _vm$form3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('div', {
    staticClass: "page-text font-weight-medium mb-4"
  }, [_vm._v(_vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$writer = _vm$form.writer) === null || _vm$form$writer === void 0 ? void 0 : _vm$form$writer.nickname))]), !_vm.isEdit ? _c('p', {
    staticClass: "page-text page-text--sm grey--text mb-8 text-pre-wrap"
  }, [_vm._v(_vm._s((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : _vm$form2.content))]) : [_c('v-divider', {
    staticClass: "mt-8",
    staticStyle: {
      "border-color": "var(--v-grey-lighten3)"
    }
  }), _c('v-sheet', {
    staticClass: "comment-input pa-12 px-md-20 py-md-16",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-textarea', {
    attrs: {
      "no-resize": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "grey darken-4",
      "disabled": !_vm.isWriter
    },
    on: {
      "click": _vm.write
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("수정하기")])])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mb-8",
    staticStyle: {
      "border-color": "var(--v-grey-lighten3)"
    }
  })], _c('v-row', {
    staticClass: "row--xxs page-text page-text--sm grey--text text--lighten-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$dayjs((_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : _vm$form3.createdAt).format("YYYY-MM-DD HH:mm:ss")))])]), _vm.isWriter ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "cursor-pointer",
    on: {
      "click": function ($event) {
        _vm.isEdit = !_vm.isEdit;
      }
    }
  }, [_vm._v(_vm._s(_vm.isEdit ? "취소" : "수정"))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "cursor-pointer",
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("삭제")])])] : _vm._e()], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }