var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "금산소식",
      "tabActive": "공지사항",
      "text": "금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다.",
      "image": "/images/news/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "",
      "subtitle": "Gallery"
    }
  }, [_c('notification-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }