<template>
    <client-page>
        <sub-visual sh="금산소식" tabActive="자유게시판" text="금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다." image="/images/news/visual.jpg" />

        <page-section class="page-section--first page-section--last">
            <div class="board-container">
                <board-list-search :code="BOARD_TYPES.COMMUNITY.value" />
                <table class="board-list mt-12 mt-md-20">
                    <thead class="d-none d-lg-table-header">
                        <tr>
                            <th style="width: calc(680 / 1200 * 100%)">제목</th>
                            <th style="width: calc(200 / 1200 * 100%)">작성자</th>
                            <th style="width: calc(200 / 1200 * 100%)">작성일</th>
                            <th style="width: calc(120 / 1200 * 100%)">조회</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(board, index) in boards" :key="index" class="board-list__info-row">
                            <td align="center" class="board-list__tit" @click="$router.push(`${$route.path}/${board._id}`)">
                                <span class="font-weight-bold primary--text">[{{ board?.category?.title }}]</span>
                                {{ board?.subject }}
                            </td>
                            <td align="center" class="board-list__txt">{{ board?.writer.nickname }}</td>
                            <td align="center" class="board-list__txt">{{ $dayjs(board?.createdAt).format("YYYY-MM-DD") }}</td>
                            <td align="center" class="board-list__txt">{{ board?.viewCount }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="isLogin" class="d-flex justify-end mt-18 mt-md-24">
                    <v-btn outlined height="40" color="tertiary" class="px-18" @click="write"> 작성하기 </v-btn>
                </div>
                <div class="v-pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { BOARD_TYPES } from "@/assets/variables";
import BoardListSearch from "@/components/client/board/board-list-search.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        BoardListSearch,
    },
    data: () => ({
        BOARD_TYPES,
        boards: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        params() {
            let { ...query } = this.$route.query;
            query.code = BOARD_TYPES.COMMUNITY.value;

            return query;
        },
        isLogin() {
            return this.$store.state?.accessToken;
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                let { summary, boards } = await api.v1.boards.gets({
                    headers: { skip, limit },
                    params,
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
        write() {
            if (!this.isLogin) {
                alert("로그인 후 이용 가능합니다.");
                this.$router.push("/login");
            } else {
                this.$router.push("./freeboard/write");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
}
</style>
